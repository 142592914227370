import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss';

import { Box, Dialog, DialogContent, DialogTitle, keyframes, styled, Typography } from '@mui/material';
import { HookTypes, withHooks } from '../utils/withHooks';
import { Navigate, Route, Routes, matchPath } from 'react-router-dom';
import React, { Suspense } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import TopAppBar, { behindLoginWallPaths } from './top-app-bar/TopAppBar';
import { action, computed, configure, makeObservable } from 'mobx';

// import AboutBefareScreen from './about/AboutBefareScreen';
import AllCompaniesLandingScreen from './companyLanding/AllCompaniesLandingScreen';
import BefareLandingScreen from './companyLanding/BefareLandingScreen';

import AsyncStorageHelper from '../auth/AsyncStorageHelper';
import AuthorizeApp from '../apps/authorize-app/AuthorizeApp';
import CookieConsent from './cookies/CookieConsent';
import CookieDeclarationScreen from './cookies/CookieDeclarationScreen';
import ErrorBoundary from '../utils/ErrorBoundary';
import GdprDialog from './gdpr/GdprDialog';
import Helmet from 'react-helmet';
import HowItWorks from './how-it-works/HowItWorks';
import LogUtil from '../helpers/LogUtil';
import Login from './login/Login';
import NavigationBar from './navigation-bar/NavigationBar';
import OrderChatConfirmationScreen from './order-chat/OrderChatConfirmationScreen';
import PrivacyPolicy from './privacy-policy/PrivacyPolicy';
import PrivateRoute from './common/PrivateRoute';
import { RootStore } from '../stores/RootStore';
import StoreContext from '../stores/StoreContext';
import VerificationCode from './login/VerificationCode';

import WorkspacePicker from './login/WorkspacePicker';
import { observer } from 'mobx-react';
import PageLoading from './common/pageLoading/PageLoading';
import SignedInGuard from '../utils/SignedInGuard';

import { Worker } from '@react-pdf-viewer/core';
import OrderChatScreen2 from './order-chat-2/OrderChatScreen2';
import ElectricCarChargerArticle from './articles/ElectricCarChargerArticle';
import FuseBoxArticle from './articles/FuseBoxArticle';
import ElectricOutletArticle from './articles/ElectricOutletArticle';
import LightingArticle from './articles/LightingArticle';
import HeatingArticle from './articles/HeatingArticle';
import CustomerSupportChatScreen from './customer-support-chat/CustomerSupportChatScreen';
import Home from './home/Home';
import LoginMagicLinkSent from './login/LoginMagicLinkSent';
import Campaign from './campaigns/Campaign';

// These should probably be lazy loaded
const Profile = React.lazy(() => import('./profile/Profile'));
const ChannelInfo = React.lazy(() => import('./channel-info/ChannelInfo'));
const Chat = React.lazy(() => import('./chat/Chat'));

const VideoCall2Screen = React.lazy(() => import('./videoCall2/VideoCall2Screen'));
const VideoCallComponent = React.lazy(() => import('./video-call/VideoCallComponent'));

// const AboutWorkspaceScreen = React.lazy(() => import('./about/AboutWorkspaceScreen'));
const CompanyLandingScreen = React.lazy(() => import('./companyLanding/CompanyLandingScreen'));

const NearMe = React.lazy(() => import('./near-me/NearMe'));

const ProjectInfo = React.lazy(() => import('./project-info/ProjectInfo'));
const Settings = React.lazy(() => import('./settings/Settings'));
const Permissions = React.lazy(() => import('./login/Permissions'));

const Dashboard = React.lazy(() => import('./dashboard/Dashboard'));
const ProjectChat = React.lazy(() => import('./chat/ProjectChat'));

const AdminScreen = React.lazy(() => import('./admin/AdminScreen'));
const Dashboard2 = React.lazy(() => import('./dashboard2/Dashboard2'));

const fadeIn = keyframes`
  from {
    opacity: 0;
	background-color: #212121;
  }
  to {
    opacity: 1;
	background-color: transparent;
  }
`;

const AppWrapper = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	opacity: 0,
	animation: `${fadeIn} 0.5s ease-in-out`,
	animationFillMode: 'forwards',
}));

// Make MobX strict to see all warnings
configure({
	enforceActions: 'observed',
	computedRequiresReaction: true,
	reactionRequiresObservable: true,
	observableRequiresReaction: true,
	disableErrorBoundaries: false, // todo: evaluate if this should be true, changed april 9. 2024
});

type Props = HookTypes;

const App = observer(
	class App extends React.Component<Props> {
		static readonly contextType = StoreContext;

		get location() {
			return this.props.location!;
		}

		get history() {
			return this.props.navigate!;
		}

		isOnlineToast: any;
		isInitiated = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				rootStore: computed,
				profileStore: computed,
				workspaceStore: computed,
				userStore: computed,
				uiState: computed,
				processQueryParams: computed,
				queryParams: computed,
				init: action,
			});
		}

		componentDidMount() {
			this.init();
		}

		init() {
			if (this.isInitiated) {
				return;
			}
			this.isInitiated = true;

			//  set redirect path
			AsyncStorageHelper.setCache(`@BefWeb:login:redirect`, window.location.pathname);

			this.userStore.SignedOut.on(() => {
				// console.log("Got signed out in app component");
				// @todo handle when session expores
				// if on home or a route that allowes users that's not signed in
				// If on another page -> sign in popup or redirect to sign in?
			});

			this.workspaceStore.WorkspaceLoaded.on(() => {
				this.forceUpdate();
			});

			this.rootStore.Navigate.on((options: any) => {
				if (options.historyOnly) {
					this.history(options.path);
				} else {
					window.location.pathname = options.path;
				}
			});

			this.rootStore.Offline.on(() => {
				toast('Du er offline', {
					toastId: 'offline-notification',
					autoClose: false,
					type: 'warning',
				});
			});

			this.rootStore.Online.on(() => {
				toast.update('offline-notification', {
					render: 'Tilkoblingen er gjenopprettet',
					autoClose: 3000,
					type: 'success',
				});
			});

			if (this.isDevEnvironment && this.queryParams?.debug) {
				localStorage.setItem('@Befare:allow-dev', 'yes');
			}

			try {
				if (this.buildVersion) {
					console.log(`%cBefare: ${this.buildVersion}`, 'color:blue; font-size:20px');
				}

				if (this.shouldRedirectFromDevToProd) {
					setTimeout(() => {
						window.location.href = 'https://befare.no';
					}, 5000);
				}
			} catch (err) {
				LogUtil.error(err);
			}
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get currentUserId() {
			return this.profileStore.currentUserProfile?.userId;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get isDevEnvironment() {
			return window.location.host.indexOf('dev') !== -1;
		}

		get processQueryParams() {
			if (this.queryParams?.t && !this.location.pathname.includes('sign-in')) {
				return true;
			} else if (this.queryParams?.c) {
				return true;
			} else {
				return false;
			}
		}

		get queryParams() {
			return this.props.queryObj;
		}

		get shouldRedirectFromDevToProd() {
			try {
				const allowDev = localStorage.getItem('@Befare:allow-dev');
				return this.isDevEnvironment && !allowDev;
			} catch (err) {
				LogUtil.error(err);
				return false;
			}
		}

		get buildVersion() {
			const appVersion = document.querySelector('meta[build-version]');
			if (appVersion?.getAttribute('build-version')) {
				return appVersion.getAttribute('build-version');
			}
			return 'unknown';
		}

		renderAppRoutes() {
			const { isLoggedIn, workspace, redirectPath } = this.userStore;
			const { hasAudiovisualPermissions, isMobile } = this.uiState;

			return (
				<Route path="/app/*" element={<PrivateRoute path="/*" isAllowed={isLoggedIn} />}>
					<Route path="sign-in/workspace" element={<WorkspacePicker />} />

					<Route path="pro/*" element={<Dashboard2 />} />

					<Route path="chat/:channelId?" element={<Navigate replace to={`/app/dashboard`} />} />
					<Route
						path="dashboard"
						element={workspace ? <Navigate to="/app/pro/dashboard" /> : <Dashboard />}
					/>
					<Route path="inbox" element={<Navigate replace to={`/app/dashboard`} />} />
					<Route
						path="inbox/chat/:channelId"
						element={isMobile ? <Chat /> : <Navigate replace to={`/app/dashboard`} />}
					/>
					<Route path="settings" element={<Settings />} />
					<Route path="profile/:userId?" element={<Profile />} />
					<Route path="profile" element={<Profile />} />
					<Route path="projects/:projectId/chat/:channelId" element={<ProjectChat />} />
					<Route path="projects/:projectId/info" element={<ProjectInfo />} />
					<Route path="channel/:channelId/info" element={<ChannelInfo />} />
					<Route path="admin/:path" element={<AdminScreen />} />
					<Route path="privacy-policy" element={<PrivacyPolicy />} />
					<Route
						path="permissions"
						element={hasAudiovisualPermissions ? <Navigate to={redirectPath} /> : <Permissions />}
					/>
					<Route path="*" element={<Navigate to="/app/dashboard" />} />
				</Route>
			);
		}

		closeUiStatePermissions = () => {
			this.uiState.initPermissions(false);
		};

		renderRoutes() {
			const { isLoggedIn, workspace } = this.userStore;
			const { isComputer, customerSupportChatOpen } = this.uiState;

			const isBehindLoginWall = Boolean(matchPath('app/*', this.location.pathname));

			const isCookieDeclarationScreen = matchPath(this.location.pathname, '/cookie-declaration');

			// Patch, we don't want any other components showing when the authorize app is showing
			if (matchPath(this.location.pathname, '/authorize/app')) {
				return <AuthorizeApp />;
			}

			// Redirect old paths to the new app/* structure
			const isOldPathBehindLoginWall = Boolean(
				behindLoginWallPaths.some((path: string) => matchPath(path, this.location.pathname))
			);
			const newPath = `/app${this.location.pathname}`;
			if (!isBehindLoginWall && isOldPathBehindLoginWall && !this.queryParams?.t) {
				console.warn(`REDIRECT: ${this.location.pathname} -> ${newPath}`);
				this.history(newPath, { replace: true });
			}

			if (isBehindLoginWall && !isLoggedIn) {
				return <Navigate to="/sign-in" />;
			}

			const preventWorkspaceSelectorRoutes = [
				'/app/sign-in/workspace',
				'/order-chat/confirmation',
				'/order-chat/:postCode/:companyId',
				'/quote/:service',
				'/order-chat',
			];

			const preventWorkspaceSelector = Boolean(
				preventWorkspaceSelectorRoutes.some((path: string) => matchPath(path, this.location.pathname))
			);
			// Redirect to workspace selector if no workspace is selected
			if (!preventWorkspaceSelector && isLoggedIn && !customerSupportChatOpen) {
				// Stored wokspace is null if set to customer view
				if (workspace === undefined) {
					console.info('Redirecting to workspace selector');
					return <Navigate to="/app/sign-in/workspace" />;
				}
			}

			return (
				<Suspense fallback={<PageLoading />}>
					<Helmet defer={false}>
						<title>{this.uiState.generateTitleString()}</title>
						<meta property="og:title" content={this.uiState.generateTitleString()} />
						<meta property="og:description" content={this.uiState.generateDescriptionString()} />
						<meta property="og:locale" content="no_NO" />
						<meta property="og:url" content={window.location.href} />
						<meta
							property="og:image"
							content={`https://befare.no/illustrations/${this.uiState.workspaceTheme}/inspection1.png`}
						/>
					</Helmet>
					<GdprDialog />
					<SignedInGuard />
					{isLoggedIn && (
						<>
							<ToastContainer position="top-right" limit={5} />
							<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js"></Worker>
							<Suspense fallback={<PageLoading />}>
								<VideoCallComponent />

								{!this.uiState.hasAudiovisualPermissions && this.uiState.requirePermissions && (
									<Dialog
										open={
											!this.uiState.hasAudiovisualPermissions && this.uiState.requirePermissions
										}
									>
										<DialogTitle></DialogTitle>
										<DialogContent>
											<ErrorBoundary>
												<Permissions
													isDialog={true}
													onCancel={this.closeUiStatePermissions}
													onSuccess={this.closeUiStatePermissions}
												/>
											</ErrorBoundary>
										</DialogContent>
									</Dialog>
								)}
							</Suspense>
						</>
					)}
					{isComputer && !isBehindLoginWall ? <NavigationBar /> : <TopAppBar />}
					<Suspense fallback={<PageLoading />}>
						<Routes>
							<Route path="/how-it-works" element={<HowItWorks />} />
							<Route path="/quote/:service" element={<CustomerSupportChatScreen />} />
							<Route path="/order-chat" element={<OrderChatScreen2 />} />
							<Route path="/order-chat/:postCode/:companyId" element={<OrderChatScreen2 />} />
							<Route path="/order-chat/confirmation" element={<OrderChatConfirmationScreen />} />
							<Route path="/call/:sessionNumber" element={<VideoCall2Screen />} />
							<Route path="/call/:sessionNumber/:code" element={<VideoCall2Screen />} />
							<Route path="/companies" element={<AllCompaniesLandingScreen />} />
							<Route path="/sign-in" element={<Login />} />
							<Route path="/sign-in/magic-link" element={<Login />} />
							<Route path="/sign-in/magiclink-sent" element={<LoginMagicLinkSent />} />
							<Route path="/near-me/:industrie/:zip/:place" element={<NearMe />} />
							<Route path="/sign-in/verification-code" element={<VerificationCode />} />
							{this.renderAppRoutes()}

							<Route path="/cookie-declaration" element={<CookieDeclarationScreen />} />
							<Route path="/privacy" element={<PrivacyPolicy />} />
							<Route path="/faq" element={<PrivacyPolicy />} />
							{/* <Route path="/about-befare" element={<AboutBefareScreen />} /> */}
							{/* <Route path="/about/:workspaceName" element={<AboutWorkspaceScreen />} /> */}
							<Route path="/about-befare" element={<BefareLandingScreen />} />
							<Route path="/about/:workspaceName" element={<CompanyLandingScreen />} />
							<Route path="/terms-of-service" element={<PrivacyPolicy />} />
							<Route path="/c/:workspaceName" element={<Home />} />
							<Route path="/elektriker" element={<Home />} />
							<Route path="/home" element={<Home />} />
							<Route path="/artikler/lade-elbil-hjemme" element={<ElectricCarChargerArticle />} />
							<Route path="/artikler/renovere-sikringsskap" element={<FuseBoxArticle />} />
							<Route path="/artikler/ny-stikkontakt" element={<ElectricOutletArticle />} />
							<Route path="/artikler/lamper-og-belysning" element={<LightingArticle />} />
							<Route path="/artikler/varmekabler" element={<HeatingArticle />} />
							<Route path="/campaign/:service/:campaignId" element={<Campaign />} />
							<Route path="/" element={isLoggedIn ? <Navigate to="/app" /> : <Home />} />
							<Route path="*" element={<Home />} />
						</Routes>
					</Suspense>

					{this.queryParams?.p && (
						<img
							src={`https://api.befare.no/v1/t/${this.queryParams?.p}`}
							style={{ width: 0, height: 0 }}
							alt=""
						/>
					)}
					{!isCookieDeclarationScreen && <CookieConsent />}
				</Suspense>
			);
		}

		renderQueryParamProcessor() {
			if (!this.processQueryParams) {
				return null;
			}

			if (this.queryParams.c) {
				return <div className="loading-wrapper">Laster firma...</div>;
			} else if (this.queryParams.t && !this.location.pathname.includes('sign-in')) {
				this.userStore.setRedirectPath(this.location.pathname);
				const redirectPath = `/sign-in/magic-link?t=${this.queryParams.t}`;
				return <Navigate to={redirectPath} />;
			} else {
				return <Typography variant="body2">Denne burde aldri vises :/</Typography>;
			}
		}

		render() {
			return (
				<AppWrapper>
					<Suspense fallback={<div>Laster...</div>}>
						<div className="App">
							{this.shouldRedirectFromDevToProd && (
								<Dialog open={this.shouldRedirectFromDevToProd}>
									<DialogContent>
										<Typography variant="body2">
											You will be redirected to befare.no in 5 seconds.
										</Typography>
									</DialogContent>
								</Dialog>
							)}

							{/* for magic link and workspace selection we need some setup before we can render routes */}
							{this.renderQueryParamProcessor()}
							{this.renderRoutes()}
						</div>
					</Suspense>
				</AppWrapper>
			);
		}
	}
);

export default withHooks(App);
