import config from '../../config/config';
import { CampaignDTO, CampaignFAQDTO, CampaignLeadDTO, DeepStatsDTO, ShallowStatsDTO } from '../../dto/campaign.types';
import { FetchWrapper, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';

export type ScrapeResult = {
	result: {
		title: string;
		description: string;
		image: string;
		services: string;
		companyDescription: string;
		ctas: string[];
	};
	url: string;
	imageUrl?: string;
};

export type CampaignStatistics = {
	stats: {
		shallowStats: ShallowStatsDTO;
		deepStats: DeepStatsDTO | null;
		faqs: CampaignFAQDTO[];
	};
};

export default class CampaignApi {
	static async getCampaign(campaignId: number): Promise<FetchWrapperTypedResponse<CampaignDTO>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}`;

		return FetchWrapper.typedGet<CampaignDTO>(url);
	}

	static async getCampaigns(): Promise<FetchWrapperTypedResponse<{ campaigns: CampaignDTO[] }>> {
		const url = `//${config.hosts.api}/v1/campaign`;

		return FetchWrapper.typedGet<{ campaigns: CampaignDTO[] }>(url);
	}

	static async createCampaign(campaign: CampaignDTO): Promise<FetchWrapperTypedResponse<{ campaign: CampaignDTO }>> {
		const url = `//${config.hosts.api}/v1/campaign`;

		return FetchWrapper.typedPost<{ campaign: CampaignDTO }>(url, {
			body: JSON.stringify({ campaign }),
		});
	}

	static async updateCampaign(campaign: CampaignDTO): Promise<FetchWrapperTypedResponse<{ campaign: CampaignDTO }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaign.id}`;

		return FetchWrapper.typedPut<{ campaign: CampaignDTO }>(url, {
			body: JSON.stringify({ campaign }),
		});
	}

	static async deleteCampaign(campaignId: number): Promise<FetchWrapperTypedResponse<{ campaignId: number }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}`;

		return FetchWrapper.typedDelete<{ campaignId: number }>(url);
	}

	// get campaign statistics
	static async getCampaignStatistics(campaignId: number): Promise<FetchWrapperTypedResponse<CampaignStatistics>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/statistics`;

		return FetchWrapper.typedGet<CampaignStatistics>(url);
	}

	// get leads
	static async getLeads(campaignId: number): Promise<FetchWrapperTypedResponse<{ leads: CampaignLeadDTO[] }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/leads`;

		return FetchWrapper.typedGet<{ leads: CampaignLeadDTO[] }>(url);
	}

	// scrape url
	static async scrapeUrl(url: string): Promise<FetchWrapperTypedResponse<ScrapeResult>> {
		const fetchUrl = `//${config.hosts.api}/v1/campaign/autofill?url=${encodeURIComponent(url)}`;

		return FetchWrapper.typedGet<ScrapeResult>(fetchUrl);
	}
}
