import { makeAutoObservable, runInAction } from 'mobx';
import CampaignApi from '../../api/endpoints/CampaignApi';
import {
	BudgetCurrencyEnum,
	CampaignDTO,
	CampaignFAQDTO,
	CampaignLeadDTO,
	CampaignStatusEnum,
	CampaignTypeEnum,
	DeepStatsDTO,
	ShallowStatsDTO,
} from '../../dto/campaign.types';
import CampaignLead from './CampaignLead';

export default class Campaign implements CampaignDTO {
	// types from campaignDTO
	id: number;
	created: Date;
	updated: Date;
	deleted?: Date;
	name: string;
	startTime: Date;
	endTime: Date;
	publishTime: Date;
	budget: number;
	budgetCurrency: BudgetCurrencyEnum = BudgetCurrencyEnum.NOK;
	workspaceId: number;
	status: CampaignStatusEnum;
	description?: string;
	url?: string;
	type: CampaignTypeEnum = CampaignTypeEnum.Basic;

	costPerClick: number = 0;
	costPerConversion: number = 25;
	costPerResolution: number = 10;

	// more things here

	leads: CampaignLead[] = [];
	shallowStats: ShallowStatsDTO | null = null;
	deepStats: DeepStatsDTO | null = null;
	faqs: CampaignFAQDTO[] = [];

	constructor(dto: CampaignDTO) {
		// happy lint
		this.id = dto.id ?? 0;
		this.created = dto.created ?? new Date();
		this.updated = dto.updated ?? new Date();
		this.name = dto.name;
		this.startTime = dto.startTime ?? new Date();
		this.endTime = dto.endTime ?? new Date();
		this.publishTime = dto.publishTime ?? new Date();
		this.budget = dto.budget ?? 0;
		this.workspaceId = dto.workspaceId;
		this.status = dto.status;
		this.updateFromDTO(dto);

		makeAutoObservable(this);

		if (!this.shallowStats) {
			this.getStats().catch(console.warn);
		}

		if (this.id) {
			this.getLeads().catch(console.warn);
		}
	}

	public addOrUpdateLead(lead: CampaignLeadDTO): void {
		const existingLead = this.leads.find((l) => l.id === lead.id);
		if (existingLead) {
			existingLead.updateFromDTO(lead);
		} else {
			this.leads.push(new CampaignLead(lead));
		}
	}

	public getMostRecentLeads(count: number): CampaignLead[] {
		return this.leads.slice(0, count).sort((a, b) => {
			if (!a.created || !b.created) {
				return 0;
			}
			return a.created > b.created ? -1 : 1;
		});
	}

	/**
	 * Update campaign properties from a DTO
	 * @param {Partial<CampaignDTO>} dto
	 */
	public updateFromDTO(dto: Partial<CampaignDTO>): void {
		this.id = dto.id ?? this.id;
		this.created = dto.created ?? this.created;
		this.updated = dto.updated ?? this.updated;
		this.deleted = dto.deleted ?? this.deleted;
		this.name = dto.name ?? this.name;
		this.startTime = dto.startTime ?? this.startTime;
		this.endTime = dto.endTime ?? this.endTime;
		this.publishTime = dto.publishTime ?? this.publishTime;
		this.budget = dto.budget ?? this.budget;
		this.budgetCurrency = dto.budgetCurrency ?? this.budgetCurrency;
		this.workspaceId = dto.workspaceId ?? this.workspaceId;
		this.status = dto.status ?? this.status;
		this.description = dto.description ?? this.description;

		this.type = dto.type ?? this.type;

		this.url = dto.url ?? this.url;
		this.costPerClick = dto.costPerClick ?? this.costPerClick;
		this.costPerConversion = dto.costPerConversion ?? this.costPerConversion;
		this.costPerResolution = dto.costPerResolution ?? this.costPerResolution;
		// more things here
	}

	async getStats(): Promise<void> {
		const result = await CampaignApi.getCampaignStatistics(this.id);
		if (result.statusCode === 200) {
			const { stats } = result.data;
			runInAction(() => {
				this.shallowStats = stats.shallowStats;
				this.deepStats = stats.deepStats;
				this.faqs = stats.faqs;
			});
		}
	}

	async getLeads(): Promise<void> {
		const result = await CampaignApi.getLeads(this.id);
		if (result.statusCode === 200) {
			runInAction(() => {
				result.data.leads.forEach((lead) => {
					this.addOrUpdateLead(lead);
				});
			});
		}
	}

	async save(): Promise<void> {
		const result = await CampaignApi.updateCampaign(this);
		if (result.statusCode === 200) {
			runInAction(() => {
				this.updateFromDTO(result.data.campaign);
			});
		}
	}
}
