import { makeAutoObservable } from 'mobx';
import {
	CampaignLeadDTO,
	CampaignLeadStatusEnum,
	CampaignProjectDTO,
	UserSentimentEnum,
} from '../../dto/campaign.types';

export default class CampaignLead implements CampaignLeadDTO {
	id?: number;
	created: string = new Date().toISOString();
	campaignId: number;
	channelId?: number;
	profileId: number;
	userId: number;
	sessionId: string;
	status: CampaignLeadStatusEnum;
	resolved?: Date;
	converted?: Date;
	leadScore?: number;
	sentiment?: UserSentimentEnum;
	numUserMessages?: number;
	numResponses?: number;
	latitude?: number;
	longitude?: number;
	postCode?: string;
	postArea?: string;
	country?: string;
	billedDate?: Date;
	workspaceId?: number;
	projectId?: number;
	utmSource?: string;
	utmMedium?: string;
	utmCampaign?: string;
	utmContent?: string;
	tags: string[] = [];
	category: string[] = [];
	insightSuggestion?: string;
	postProcessingDateTime?: Date;
	project?: CampaignProjectDTO;

	constructor(dto: CampaignLeadDTO) {
		this.id = dto.id;
		this.campaignId = dto.campaignId;
		this.profileId = dto.profileId;
		this.userId = dto.userId;
		this.sessionId = dto.sessionId;
		this.status = dto.status;
		this.updateFromDTO(dto);

		makeAutoObservable(this);
	}

	public updateFromDTO(dto: Partial<CampaignLeadDTO>): void {
		this.id = dto.id ?? this.id;
		this.created = dto.created ?? this.created;
		this.campaignId = dto.campaignId ?? this.campaignId;
		this.channelId = dto.channelId ?? this.channelId;
		this.profileId = dto.profileId ?? this.profileId;
		this.userId = dto.userId ?? this.userId;
		this.sessionId = dto.sessionId ?? this.sessionId;
		this.status = dto.status ?? this.status;
		this.resolved = dto.resolved ?? this.resolved;
		this.converted = dto.converted ?? this.converted;
		this.leadScore = dto.leadScore ?? this.leadScore;
		this.sentiment = dto.sentiment ?? this.sentiment;
		this.numUserMessages = dto.numUserMessages ?? this.numUserMessages;
		this.numResponses = dto.numResponses ?? this.numResponses;
		this.latitude = dto.latitude ?? this.latitude;
		this.longitude = dto.longitude ?? this.longitude;
		this.postCode = dto.postCode ?? this.postCode;
		this.postArea = dto.postArea ?? this.postArea;
		this.country = dto.country ?? this.country;
		this.billedDate = dto.billedDate ?? this.billedDate;
		this.workspaceId = dto.workspaceId ?? this.workspaceId;
		this.projectId = dto.projectId ?? this.projectId;
		this.utmSource = dto.utmSource ?? this.utmSource;
		this.utmMedium = dto.utmMedium ?? this.utmMedium;
		this.utmCampaign = dto.utmCampaign ?? this.utmCampaign;
		this.utmContent = dto.utmContent ?? this.utmContent;
		this.tags = dto.tags ?? this.tags;
		this.category = dto.category ?? this.category;
		this.insightSuggestion = dto.insightSuggestion ?? this.insightSuggestion;
		this.postProcessingDateTime = dto.postProcessingDateTime ?? this.postProcessingDateTime;
		this.project = dto.project ?? this.project;

		// todo: remove this
		this.patchLatLon();
	}

	private patchLatLon(): void {
		if (!this.latitude || !this.longitude) {
			this.latitude = 59.911491 + Math.random() * 2;
			this.longitude = 10.0 + Math.random() * 0.5;
		}
	}
}
