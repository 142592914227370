import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from '../RootStore';
import Campaign from './Campaign';
import CampaignApi from '../../api/endpoints/CampaignApi';
import { CampaignDTO, CampaignLeadDTO } from '../../dto/campaign.types';
import {
	CampaignLeadLogTriggerData,
	CampaignLeadTriggerData,
	CampaignTriggerData,
	Trigger,
	TriggerType,
} from '../../Trigger.types';

export class CampaignStore {
	rootStore: RootStore;

	campaigns: Campaign[] = [];
	isLoadingCampaigns = false;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
		this.processTrigger = this.processTrigger.bind(this);
		this.init();
	}

	init() {
		// initialize if needed
	}

	destroy() {
		// destroy if needed
		this.campaigns = [];
	}

	processTrigger(trigger: Trigger<CampaignTriggerData | CampaignLeadTriggerData | CampaignLeadLogTriggerData>) {
		const data = trigger.event.data;
		console.log('Processing CAMPAIGN trigger', trigger.urn, data);
		switch (trigger.urn) {
			case TriggerType.CAMPAIGN_CREATED:
			case TriggerType.CAMPAIGN_UPDATED:
			case TriggerType.CAMPAIGN_DELETED:
				this.addOrUpdateCampaign(data as CampaignDTO);
				break;
			case TriggerType.CAMPAIGN_LEAD_CREATED:
			case TriggerType.CAMPAIGN_LEAD_UPDATED:
				this.addOrUpdateLead(data as CampaignLeadDTO);
				break;
			default:
				break;
		}
	}

	getCampaign(id?: number): Campaign | null {
		if (!id) {
			return null;
		}
		return this.campaigns.find((c) => c.id === id) ?? null;
	}

	async loadCampaign(id: number) {
		const response = await CampaignApi.getCampaign(id);
		if (response.statusCode === 200) {
			this.addOrUpdateCampaign(response.data);
		}
	}

	async loadCampaigns() {
		if (this.isLoadingCampaigns) {
			return;
		}
		// load campaigns from server
		this.isLoadingCampaigns = true;
		const response = await CampaignApi.getCampaigns().finally(() => {
			runInAction(() => {
				this.isLoadingCampaigns = false;
			});
		});
		if (response.statusCode === 200) {
			this.addOrUpdateCampaigns(response.data.campaigns);
		}
	}

	addOrUpdateCampaigns(campaigns: CampaignDTO[]) {
		for (const campaign of campaigns) {
			this.addOrUpdateCampaign(campaign);
		}
	}

	addOrUpdateCampaign(campaign: CampaignDTO) {
		const existingCampaign = this.campaigns.find((c) => c.id === campaign.id);
		if (existingCampaign) {
			existingCampaign.updateFromDTO(campaign);
		} else {
			this.campaigns.push(new Campaign(campaign));
		}
	}

	addOrUpdateLead(lead: CampaignLeadDTO) {
		const campaign = this.campaigns.find((c) => c.id === lead.campaignId);
		if (campaign) {
			campaign.addOrUpdateLead(lead);
		}
	}
}
